
import {IonApp, IonRouterOutlet, toastController, alertController} from '@ionic/vue';
import {defineComponent} from 'vue';
import {emitter} from "@/util/NotificationService";
import {removeUserFromStorage, removeUserRatingFromStorage} from "@/util/LocalStateService";

export default defineComponent({
    name: 'App',
    components: {
        IonApp,
        IonRouterOutlet,
    },
    methods:{
        async openNotificationToast(e: string) {
            const toast = await toastController
                .create({
                    message: e,
                    header: 'Information:',
                    duration: 5000,
                    color: "success",
                    position: "bottom",
                    cssClass: 'notificationToast',
                    mode: "ios",
                })
            return toast.present();
        },
        async openWarningToast(e: string) {
            const toast = await toastController
                .create({
                    message: e,
                    header: 'Fehler:',
                    duration: 8000,
                    color: "danger",
                    position: "bottom",
                    cssClass: 'notificationToast',
                    mode: "ios",
                })
            return toast.present();
        },
    },
    mounted() {

        emitter.on('error', (e) => {
            console.log(e);
            this.openWarningToast(e)
            removeUserFromStorage()
            removeUserRatingFromStorage().then(()=>{this.$router.push('/login')})
        });

        emitter.on('notification', (e) => {
            this.openNotificationToast(e)
        })
        emitter.on('reFetch', (e) => {
            this.openWarningToast(e)
        })
        emitter.on('reMutate', (e) => {
            this.openWarningToast(e.text)
        })
        emitter.on('errorAlert', () => {
            alertController.create({
                message: 'Beim Laden der Daten ist ein Fehler aufgetreten. Bitte wenden Sie sich an einen Administrator.',
                buttons: [
                    {
                        text: 'OK',
                        role: 'confirm',
                    },
                    {
                        text: 'Reload',
                        role: 'cancel',
                        handler: () => {
                            window.location.reload();
                        },
                    },
                ],
            }).then((alert) => {
                alert.present();
            });
        });
    }
});
