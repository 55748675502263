import {client} from "@/main";
import contest from "@/graphql/contest.graphql";
import {ref, Ref} from "vue";
import {Juror, JurorList, juryList} from "@/util/JuryService";
import {Table, tableList, TableList} from "@/util/TableService";
import {Plate, PlateList} from "@/util/PlateService";
import {Cheese, cheeseList} from "@/util/CheeseService";
import {loadJurorFromStorage, logout, removeUserFromStorage} from "@/util/LocalStateService";
import {fetchResultPoints,} from "@/util/ResultService";
import {emitter} from "@/util/NotificationService";
import {Storage} from "@capacitor/storage";


export const currentContest = ref<Contest | null>(null)

export class Contest {
    private readonly _title: string
    private readonly _teaser: string
    private readonly _description: string
    private _jurylist: JurorList // global list of Jurors used for listing
    private _tablelist: TableList // global list of Tables used for listing
    currentJurySessionId: string

    constructor(id: number, title: string, teaser: string, description: string, juryList: JurorList, tableList: TableList, currentJurySessionId: string) {
        this._id = id
        this._title = title
        this._teaser = teaser
        this._description = description
        this._jurylist = juryList
        this._tablelist = tableList
        this.currentJurySessionId = currentJurySessionId;
    }

    private _id: number

    get id(): number {
        return this._id;
    }

    get title(): string {
        return this._title;
    }

    get teaser(): string {
        return this._teaser;
    }

    get description(): string {
        return this._description;
    }


    getPlates(tableID: number): any {
        const t = this._tablelist.filter(function (el) {
            return el.id == tableID
        });
        return t[0].plateList
    }

    getParticipant(cheeseId: number): any {
        const t = cheeseList.value.filter(function (el) {
            return Number(el.id) === Number(cheeseId)
        });
        return t[0]
    }

    getTables(): TableList | null {
        return this._tablelist
    }
}

// Converts json response to Contest object with Juror objects, Table and Plate and Cheese objects.
// Triggered only on app load

async function buildData(contest: any, jurySession: any): Promise<void> {
    juryList.value = []
    tableList.value = []
    cheeseList.value = []


    if (contest.findContest.length > 0) {
        const c = contest.findContest[0]
        if(jurySession.findJurySession.length > 0){
            const currentJurySession = jurySession.findJurySession[0]

            const j = currentJurySession.jurors

            for (const i of Object.keys(j)) {
                const ji = j[i]
                juryList.value.push(new Juror(ji.id, ji.firstName, ji.lastName))
            }
            const t = currentJurySession.tables
            for (const i of Object.keys(t)) {
                const ti = t[i]
                const p = ti.plates
                const plateList: PlateList = []
                for (const i of Object.keys(p)) {
                    const pi = p[i]
                    const participant = new Cheese(
                        pi.cheese.id, pi.cheese.title, pi.coding, pi.cheese.contestCode, pi.cheese.innovation
                    )
                    participant.juryDescription = pi.cheese.juryDescription;
                    participant.benefitDistributor = pi.cheese.benefitDistributor;
                    participant.benefitConsumer = pi.cheese.benefitConsumer;
                    participant.concept = pi.cheese.concept;
                    participant.look = pi.cheese.look;
                    participant.productionMethod = pi.cheese.productionMethod;
                    participant.recipe = pi.cheese.recipe;
                    participant.taste = pi.cheese.taste;
                    participant.cheeseType = pi.cheese.cheeseType ? pi.cheese.cheeseType.title : undefined;
                    participant.maturation = pi.cheese.maturation ? pi.cheese.maturation.title : undefined;
                    participant.milkType = pi.cheese.milkType ? pi.cheese.milkType.title : undefined;
                    participant.milkTypeAddition = pi.cheese.cheese.milkTypeAddition;
                    participant.fdm = pi.cheese.fdm;
                    cheeseList.value.push(participant)
                    plateList.push(new Plate(pi.id, pi.coding, participant))
                }
                tableList.value.push(new Table(ti.id, ti.title, plateList, ti.jurors))
            }
            currentContest.value = new Contest(c.id, c.title, c.teaser, c.description, juryList.value, tableList.value, currentJurySession.id)
            await loadJurorFromStorage();
            await fetchResultPoints();
        }else{
            emitter.emit('error', 'Es wurde keine Jurysitzung gefunden!');
        }
    } else {
        emitter.emit('error', 'Es wurde kein Wettbewerb gefunden!');
    }
}

export function getCurrentJurySession(currentContest: any): Promise<unknown> {
    if(currentContest && currentContest.findContest.length > 0 ){
        return client
            .executeQuery({
                query: contest.getCurrentJurySession,
                variables: {
                    contest: currentContest.findContest[0].id
                },
            })
            .then(response => {
                return Promise.resolve(response.data);
            })
    }else{
        setTimeout(function () {
            emitter.emit('error', 'Es wurde kein Wettbewerb gefunden!');
        }, 2000);
        removeUserFromStorage().then()
        return Promise.reject();
    }

}


export function loadContest(): Promise<Ref | void> {
    if (currentContest.value === null) {
        return client
            .executeQuery({
                query: contest.getContest,
            })
            .then((response)=> {

                if(response.data.findContest && response.data.findContest.length > 0){
                    getCurrentJurySession(response.data).then(r => {
                        buildData(response.data ?? null, r).then(() => {
                            return currentContest;
                        })
                    } )
                }
                else{
                    setTimeout(function () {
                        emitter.emit('error', 'Es wurde kein Wettbewerb gefunden!');
                    }, 1000);
                    removeUserFromStorage().then()
                }
            }).catch(() => {
                emitter.emit('error', 'Es ist ein Fehler aufgetreten!');
            })
    }
    return Promise.resolve(currentContest);
}



