import mitt from 'mitt'
import {fetchResultPoints} from "@/util/ResultService";
import {currentContest} from "@/util/ContestService";
import {Cheese} from "@/util/CheeseService";

const debounce = (fn: Function, ms = 2000) => {
    let timeoutId: ReturnType<typeof setTimeout>;
    return function (this: any, ...args: any[]) {
        clearTimeout(timeoutId);
        timeoutId = setTimeout(() => fn.apply(this, args), ms);
    };
};


export type Events = {
    notification: string;
    reFetch: string;
    error: string;
    errorAlert: null;
    reMutate: {
        text: string;
        id: number;
    };
};

export const emitter = mitt<Events>();

emitter.on('notification', (e) => {
    console.log(e)
});

emitter.on('error', (e) => {
    console.log(e)
});

emitter.on('reFetch', (e) => {
    console.log(e)
    debounce(() => {
        fetchResultPoints()
    })
});

emitter.on('reMutate', (e) => {
    console.log(e)
    debounce(() => {
        if (currentContest.value) {
            const participant: Cheese = currentContest.value.getParticipant(Number(e.id))
            participant.resultPoints?.save();
        }
    })
})
