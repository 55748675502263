import {createRouter, createWebHistory} from '@ionic/vue-router';
import {RouteRecordRaw} from 'vue-router';
import {Storage} from "@capacitor/storage";


const routes: Array<RouteRecordRaw> = [
    {
        path: '/login',
        component: () => import('@/views/Login.vue')
    },
    {
        name: 'Dashboard',
        path: '/',
        component: () => import('@/views/Dashboard.vue'),
        children: [
            {
                path: '',
                redirect: '/tables'
            },
            {
                path: 'tables',
                component: () => import('@/views/Tables.vue')
            },
            {
                path: 'openratings',
                component: () => import('@/views/Rating/OpenRatings.vue'),
            }, {
                path: 'logout',
                component: () => import('@/views/Logout.vue'),
            },
        ]
    },
    {
        name: 'Rating',
        path: '/rating',
        component: () => import('@/views/Rating/Rating.vue'),
    },
    {
        name: 'CheeseList',
        path: '/rating/cheeselist/:tableID',
        component: () => import('@/views/Rating/CheeseList.vue'),
    },
    {
        name: 'Cheese',
        path: '/rating/cheese/:cheeseID',
        component: () => import('@/views/Rating/Cheese.vue'),
    },
    {
        name: 'Innovation',
        path: '/rating/innovation/:cheeseID',
        component: () => import('@/views/Rating/Innovation.vue'),
    },
    {
        name: 'Error',
        path: '/error/:error',
        component: () => import('@/views/Error.vue'),
        props: true,
    },
    {path: '/:pathMatch(.*)*', redirect: '/tables'},


]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

router.beforeEach((to, from) => {
    if (to.path !== '/login' && to.path !== '/login/' && to.name !== 'Error') {
        Storage.get({key: 'logged_in'}).then((r) => {
            if (!r.value) {
                router.replace('/login').then()
            }
        })
    } else if (to.path === '/login' || to.path === '/login/') {
        Storage.get({key: 'logged_in'}).then((r) => {
            if (r.value) {
                router.replace('/').then()
            }
        })
    }
})

export default router
